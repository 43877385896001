<template>
  <div class="yogi-ptsal">
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <span> - </span>
        <span>
          <h3>
            #Form
            <b-badge :variant="dataID ? 'light-danger' : 'primary'">
              {{ dataID ? 'Edit Kegiatan' : 'Tambah Kegiatan' }}
            </b-badge>
            - Pelayanan Jasa Operasional
          </h3>
        </span>
      </div>
    </b-card>
    <b-card class="shadow-sm" title="Summary Pengajuan">
      <b-list-group>
        <b-list-group-item>
          <b-row no-gutters class="summary-container">
            <b-col md="3" class="summary-item">
              <strong><feather-icon icon="UsersIcon" /> Pengguna Jasa:</strong>
              <p>{{ jops_pengajuan_dt.pengguna_jasa }}</p>
            </b-col>

            <b-col md="3" class="summary-item">
              <strong><feather-icon icon="CalendarIcon" /> Tanggal:</strong>
              <p>{{ dateHumanReadAble(jops_pengajuan_dt.tanggal) }}</p>
            </b-col>

            <b-col md="3" class="summary-item">
              <strong><feather-icon icon="TrelloIcon" /> Status: </strong>
              <b-badge :variant="getStatusVariant(jops_pengajuan_dt.status)">
                {{ jops_pengajuan_dt.status }}
              </b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row no-gutters class="summary-container">
            <b-col md="5" class="summary-item">
              <strong><feather-icon icon="ToolIcon" /> Peralatan:</strong>
              <ul>
                <li>
                  <strong>Crane:</strong>
                  <span
                    v-for="(item, index) in jops_pengajuan_dt.plt_crane"
                    :id="index"
                    :key="index"
                  >
                    {{ item }} -
                  </span>
                </li>
                <li>
                  <strong>Forklift:</strong>
                  <span
                    v-for="(item, index) in jops_pengajuan_dt.plt_forklift"
                    :id="index"
                    :key="index"
                  >
                    {{ item }} -
                  </span>
                </li>
                <li>
                  <strong>Tronton:</strong>
                  <span
                    v-for="(item, index) in jops_pengajuan_dt.plt_tronton"
                    :id="index"
                    :key="index"
                  >
                    {{ item }} -
                  </span>
                </li>
              </ul>
            </b-col>

            <b-col md="7" class="summary-item">
              <strong
                ><feather-icon icon="BriefcaseIcon" /> Tenaga Kerja
                (PRS):</strong
              >
              <b-row>
                <b-col>
                  <ul>
                    <li>
                      <strong>Rigger:</strong> {{ jops_pengajuan_dt.tk_rigger }}
                    </li>
                    <li>
                      <strong>Supervisor:</strong>
                      {{ jops_pengajuan_dt.tk_supervisor }}
                    </li>
                  </ul>
                </b-col>
                <b-col>
                  <ul>
                    <li>
                      <strong>Tallyman:</strong>
                      {{ jops_pengajuan_dt.tk_tallyman }}
                    </li>
                    <li>
                      <strong>Jettyman:</strong>
                      {{ jops_pengajuan_dt.tk_jettyman }}
                    </li>
                  </ul>
                </b-col>
                <b-col>
                  <ul>
                    <li>
                      <strong>HSE:</strong> {{ jops_pengajuan_dt.tk_hse }}
                    </li>
                    <li>
                      <strong>Security:</strong>
                      {{ jops_pengajuan_dt.tk_security }}
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-row class="match-height">
      <b-col md="3" cols="12">
        <b-card class="text-center yo-card" @click="() => jasaPelayanan(1)">
          <b-avatar
            v-if="form.jasa_dermaga"
            v-b-tooltip.hover
            title="Memilih Produk Ini"
            class="mb-1"
            :variant="`success`"
            size="35"
            style="position: absolute; top: 12px; right: 12px"
          >
            <feather-icon size="30" :icon="'CheckIcon'" />
          </b-avatar>

          <b-avatar
            v-b-tooltip.hover
            title="Jenis Produk"
            class="mb-1"
            :variant="`light-primary`"
            size="60"
          >
            <feather-icon size="25" :icon="'AnchorIcon'" />
          </b-avatar>
          <h3>Jasa Dermaga</h3>
        </b-card>
      </b-col>
      <b-col md="3" cols="12">
        <b-card class="text-center yo-card" @click="() => jasaPelayanan(2)">
          <b-avatar
            v-if="form.jasa_stevodoring"
            v-b-tooltip.hover
            title="Memilih Produk Ini"
            class="mb-1"
            :variant="`success`"
            size="35"
            style="position: absolute; top: 12px; right: 12px"
          >
            <feather-icon size="30" :icon="'CheckIcon'" />
          </b-avatar>

          <b-avatar
            v-b-tooltip.hover
            title="Jenis Produk"
            class="mb-1"
            :variant="`light-primary`"
            size="60"
          >
            <feather-icon size="25" :icon="'TruckIcon'" />
          </b-avatar>
          <h3>Jasa Stevodoring</h3>
        </b-card>
      </b-col>
      <b-col md="3" cols="12">
        <b-card class="text-center yo-card" @click="() => jasaPelayanan(3)">
          <b-avatar
            v-if="form.jasa_cargodoring"
            v-b-tooltip.hover
            title="Memilih Produk Ini"
            class="mb-1"
            :variant="`success`"
            size="35"
            style="position: absolute; top: 12px; right: 12px"
          >
            <feather-icon size="30" :icon="'CheckIcon'" />
          </b-avatar>

          <b-avatar
            v-b-tooltip.hover
            title="Jenis Produk"
            class="mb-1"
            :variant="`light-primary`"
            size="60"
          >
            <feather-icon size="25" :icon="'BoxIcon'" />
          </b-avatar>
          <h3>Jasa Cargodoring</h3>
        </b-card>
      </b-col>
      <b-col md="3" cols="12">
        <b-card class="text-center yo-card" @click="() => jasaPelayanan(4)">
          <b-avatar
            v-if="form.lift_on_off"
            v-b-tooltip.hover
            title="Memilih Produk Ini"
            class="mb-1"
            :variant="`success`"
            size="35"
            style="position: absolute; top: 12px; right: 12px"
          >
            <feather-icon size="30" :icon="'CheckIcon'" />
          </b-avatar>

          <b-avatar
            v-b-tooltip.hover
            title="Jenis Produk"
            class="mb-1"
            :variant="`light-primary`"
            size="60"
          >
            <feather-icon size="25" :icon="'Maximize2Icon'" />
          </b-avatar>
          <h3>Lift ON / OFF</h3>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col md="6" cols="12">
          <b-form-group label="Uraian Kegiatan" label-for="lbl-Uraian Kegiatan">
            <validation-provider
              #default="{ errors }"
              name="Uraian Kegiatan"
              vid="Uraian Kegiatan"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="TrelloIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="lbl-Uraian Kegiatan"
                  v-model="form.uraian_kegiatan"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="''"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" cols="12">
          <b-form-group label="Note" label-for="lbl-Note">
            <validation-provider #default="{ errors }" name="Note" vid="Note">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="AlertCircleIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="lbl-Note"
                  v-model="form.note"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="''"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="divider">
            <div class="divider-text">
              <b-badge pill class="d-block" :variant="'light-primary'">
                Jumlah dan Satuan ( Choose one or Choose Both )
              </b-badge>
            </div>
          </div>
        </b-col>
        <b-col md="6" cols="12">
          <b-form-group label="Jumlah" label-for="lbl-Jumlah1">
            <validation-provider
              #default="{ errors }"
              name="Jumlah1"
              vid="Jumlah1"
              :rules="{
                required: false
              }"
            >
              <b-input-group>
                <b-form-input
                  ref="Jumlah1Input"
                  id="lbl-Jumlah1"
                  v-model="form.jumlah1"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :placeholder="'Cth 12'"
                />
                <b-input-group-prepend
                  is-text
                  @click="$refs.Jumlah1Input.focus()"
                >
                  TON
                </b-input-group-prepend>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" cols="12">
          <b-form-group label="Jumlah" label-for="lbl-Jumlah2">
            <validation-provider
              #default="{ errors }"
              name="Jumlah2"
              vid="Jumlah2"
              :rules="{
                required: false
              }"
            >
              <b-input-group>
                <b-form-input
                  ref="Jumlah2Input"
                  id="lbl-Jumlah2"
                  v-model="form.jumlah2"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :placeholder="'Cth 37.6'"
                />
                <b-input-group-prepend
                  is-text
                  @click="$refs.Jumlah2Input.focus()"
                >
                  M / M3
                </b-input-group-prepend>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Waktu Mulai Kegiatan"
            label-for="lbl-Waktu Mulai Kegiatan"
          >
            <!-- regex: /^[0-9]+$/ -->
            <validation-provider
              #default="{ errors }"
              name="Waktu Mulai Kegiatan"
              vid="Waktu Mulai Kegiatan"
              :rules="{
                required_if: 'Waktu Selesai Kegiatan'
              }"
            >
              <!-- v-if="!permissionSalOnly()" -->
              <flat-pickr
                v-model="form.mulai"
                class="form-control"
                static="true"
                placeholder="Waktu Mulai Kegiatan"
                :state="errors.length > 0 ? false : null"
                :config="dpconfig"
                @on-change="onStartChange"
              />
              <!-- <b-form-input
                  v-else
                  id="lbl-Waktu Mulai Kegiatan"
                  :value="formatDateTime(form.schedule.sandar_start_time)"
                  :state="errors.length > 0 ? false : null"
                  :readonly="permissionSalOnly()"
                  placeholder="Diisi Admin SAL | Jetty Man"
                /> -->
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Waktu Selesai Kegiatan"
            label-for="lbl-Waktu Selesai Kegiatan"
          >
            <!-- regex: /^[0-9]+$/ -->
            <validation-provider
              #default="{ errors }"
              name="Waktu Selesai Kegiatan"
              vid="Waktu Selesai Kegiatan"
              :rules="{
                required: false
              }"
            >
              <flat-pickr
                v-model="form.selesai"
                class="form-control"
                static="true"
                placeholder="Waktu Selesai Kegiatan"
                :state="errors.length > 0 ? false : null"
                :config="configs.end"
                @on-change="onEndChange"
              />
              <!-- <b-form-input
                  v-else
                  id="lbl-Waktu Selesai Kegiatan"
                  :value="formatDateTime(form.schedule.sandar_end_time)"
                  :state="errors.length > 0 ? false : null"
                  :readonly="permissionSalOnly()"
                  placeholder="Diisi Admin SAL | Jetty Man"
                /> -->

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12" cols="12">
          <b-button
            class="mt-1"
            block
            :variant="dataID ? 'gradient-warning' : 'gradient-primary'"
            :disabled="fetchLoading"
            @click="handleSubmit()"
          >
            <feather-icon
              v-if="!fetchLoading"
              icon="DatabaseIcon"
              :style="{ cursor: 'pointer' }"
              class="mr-50"
            />
            <b-spinner v-if="fetchLoading" small></b-spinner>
            <span
              class="align-middle"
              v-text="
                dataID
                  ? 'Submit Edit Kegiatan Operasional'
                  : 'Submit Tambah Kegiatan Operasional'
              "
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCardText,
  BAvatar,
  BBadge,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BBadge,
    Indonesian,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCardActions,
    BCardText,
    Cleave
  },
  mixins: [heightTransition],
  data() {
    return {
      dataID: this.$router.currentRoute.params.id,
      jops_pengajuan_dt: this.$router.currentRoute.params.jops_pengajuan_dt,
      jops_pengajuan_dt_id:
        this.$router.currentRoute.params.jops_pengajuan_dt_id,
      fetchLoading: false,
      form: {
        uraian_kegiatan: '',
        jumlah1: '',
        jumlah_s1: '',
        jumlah2: '',
        jumlah_s2: '',
        mulai: '',
        selesai: '',
        jasa_dermaga: false,
        jasa_stevodoring: false,
        jasa_cargodoring: false,
        lift_on_off: false,
        note: ''
      },
      dpconfig: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: true,
        // altInput: true,
        // dateFormat: 'Y-m-d H:i',
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          altFormat: 'j F Y - H:i',
          dateFormat: 'Y-m-d H:i',
          locale: Indonesian,
          minDate: null
        }
      }
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    getStatusVariant(status) {
      switch (status) {
        case 'IN-PROGRESS':
          return 'warning' // Yellow
        case 'COMPLETED':
          return 'success' // Green
        case 'PENDING':
          return 'danger' // Red
        case 'PLAN':
          return 'primary' // Blue
        case 'DAILY':
          return 'warning' // Light Blue
        case 'JR':
          return 'success' // Black/Gray
        default:
          return 'secondary' // Gray
      }
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    async fetchData() {
      const dataID = this.dataID
      if (dataID !== undefined) {
        /* edit data */
        this.fetchLoading = true
        await useJwt.http
          .get(`jops_pengajuan_dts_keg/${dataID}`)
          .then((response) => {
            this.fetchLoading = false
            const {
              uraian_kegiatan,
              jumlah1,
              jumlah_s1,
              jumlah2,
              jumlah_s2,
              mulai,
              selesai,
              jasa_dermaga,
              jasa_stevodoring,
              jasa_cargodoring,
              lift_on_off,
              note
            } = response.data[`jops_pengajuan_dts_kegs`]
            this.form.uraian_kegiatan = uraian_kegiatan
            this.form.jumlah1 = jumlah1
            this.form.jumlah_s1 = jumlah_s1
            this.form.jumlah2 = jumlah2
            this.form.jumlah_s2 = jumlah_s2
            this.form.mulai = mulai
            this.form.selesai = selesai
            this.form.jasa_dermaga = jasa_dermaga
            this.form.jasa_stevodoring = jasa_stevodoring
            this.form.jasa_cargodoring = jasa_cargodoring
            this.form.lift_on_off = lift_on_off
            this.form.note = note
          })
      }
    },
    async handleSubmit() {
      this.fetchLoading = true
      let payload = {
        ...this.form,
        jops_pengajuan_dt_id: this.jops_pengajuan_dt_id
      }

      if (this.dataID) {
        await useJwt.http
          .put(`jops_pengajuan_dts_keg/${this.dataID}`, payload)
          .then(() => {
            this.fetchLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted - Updated',
                icon: 'SaveIcon',
                variant: 'success'
              }
            })
            this.$router.go(-1)
          })
          .catch((error) => {
            this.fetchLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted - Created',
                icon: 'XCircleIcon',
                variant: 'danger'
              }
            })
          })
      } else {
        await useJwt.http
          .post('jops_pengajuan_dts_keg', payload)
          .then(() => {
            this.fetchLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted',
                icon: 'SaveIcon',
                variant: 'success'
              }
            })
            this.$router.go(-1)
          })
          .catch((error) => {
            this.fetchLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted',
                icon: 'XCircleIcon',
                variant: 'danger'
              }
            })
          })
      }
    },
    jasaPelayanan(value) {
      // Reset all fields to false
      // this.form.jasa_dermaga = false;
      // this.form.jasa_stevodoring = false;
      // this.form.jasa_cargodoring = false;
      // this.form.lift_on_off = false;

      // Set the selected one to true
      if (value === 1) {
        this.form.jasa_dermaga = !this.form.jasa_dermaga
      } else if (value === 2) {
        this.form.jasa_stevodoring = !this.form.jasa_stevodoring
      } else if (value === 3) {
        this.form.jasa_cargodoring = !this.form.jasa_cargodoring
      } else if (value === 4) {
        this.form.lift_on_off = !this.form.lift_on_off
      }
    },
    onStartChange(selectedDates, dateStr, instance, model) {
      console.log('selectedDates', selectedDates)
      console.log('dateStr', dateStr)
      console.log('instance', instance)
      console.log('model', model)
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.dpconfig.maxDate = dateStr
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;

#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-global-search {
            border-top: 5px solid #2f2f2f1b;
            border-left: 5px solid #2f2f2f1b;
            border-right: 5px solid #2f2f2f1b;

            .vgt-global-search__input {
              border: none !important;

              .input__icon {
                font-weight: 700 !important;
              }

              .vgt-input {
                font-weight: 700 !important;
                color: black;
                border: double;
              }
            }
          }

          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;

            thead {
              th {
                border: $color-yo-border !important;
              }

              /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }

            tbody {
              tr.custom-transition {
                transition: opacity 0.5s ease;
              }

              tr.custom-transition-active,
              tr.custom-transition-leave-active {
                transition: opacity 0.5s ease;
              }

              tr.custom-transition-enter-from,
              tr.custom-transition-leave-to {
                opacity: 0;
              }

              th {
                border: $color-yo-border !important;
              }

              /* left checkbox */
              td {
                border: $color-yo-border !important;
              }

              /* right checkbox */
            }
          }

          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }

            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.yogi-ptsal {
  .yo-card {
    &:hover {
      transition: all 0.1s;
      scale: 1.1;
      box-shadow: 0 4px 8px rgb(0, 119, 255);
      opacity: 0.9;
      cursor: pointer;
    }
  }
}
</style>
