var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "yogi-ptsal"
  }, [_c('b-card', [_c('div', {
    staticClass: "custom-search d-flex justify-content-between"
  }, [_c('span', [_vm._v(" - ")]), _c('span', [_c('h3', [_vm._v(" #Form "), _c('b-badge', {
    attrs: {
      "variant": _vm.dataID ? 'light-danger' : 'primary'
    }
  }, [_vm._v(" " + _vm._s(_vm.dataID ? 'Edit Kegiatan' : 'Tambah Kegiatan') + " ")]), _vm._v(" - Pelayanan Jasa Operasional ")], 1)])])]), _c('b-card', {
    staticClass: "shadow-sm",
    attrs: {
      "title": "Summary Pengajuan"
    }
  }, [_c('b-list-group', [_c('b-list-group-item', [_c('b-row', {
    staticClass: "summary-container",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "summary-item",
    attrs: {
      "md": "3"
    }
  }, [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "UsersIcon"
    }
  }), _vm._v(" Pengguna Jasa:")], 1), _c('p', [_vm._v(_vm._s(_vm.jops_pengajuan_dt.pengguna_jasa))])]), _c('b-col', {
    staticClass: "summary-item",
    attrs: {
      "md": "3"
    }
  }, [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _vm._v(" Tanggal:")], 1), _c('p', [_vm._v(_vm._s(_vm.dateHumanReadAble(_vm.jops_pengajuan_dt.tanggal)))])]), _c('b-col', {
    staticClass: "summary-item",
    attrs: {
      "md": "3"
    }
  }, [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "TrelloIcon"
    }
  }), _vm._v(" Status: ")], 1), _c('b-badge', {
    attrs: {
      "variant": _vm.getStatusVariant(_vm.jops_pengajuan_dt.status)
    }
  }, [_vm._v(" " + _vm._s(_vm.jops_pengajuan_dt.status) + " ")])], 1)], 1)], 1), _c('b-list-group-item', [_c('b-row', {
    staticClass: "summary-container",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "summary-item",
    attrs: {
      "md": "5"
    }
  }, [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "ToolIcon"
    }
  }), _vm._v(" Peralatan:")], 1), _c('ul', [_c('li', [_c('strong', [_vm._v("Crane:")]), _vm._l(_vm.jops_pengajuan_dt.plt_crane, function (item, index) {
    return _c('span', {
      key: index,
      attrs: {
        "id": index
      }
    }, [_vm._v(" " + _vm._s(item) + " - ")]);
  })], 2), _c('li', [_c('strong', [_vm._v("Forklift:")]), _vm._l(_vm.jops_pengajuan_dt.plt_forklift, function (item, index) {
    return _c('span', {
      key: index,
      attrs: {
        "id": index
      }
    }, [_vm._v(" " + _vm._s(item) + " - ")]);
  })], 2), _c('li', [_c('strong', [_vm._v("Tronton:")]), _vm._l(_vm.jops_pengajuan_dt.plt_tronton, function (item, index) {
    return _c('span', {
      key: index,
      attrs: {
        "id": index
      }
    }, [_vm._v(" " + _vm._s(item) + " - ")]);
  })], 2)])]), _c('b-col', {
    staticClass: "summary-item",
    attrs: {
      "md": "7"
    }
  }, [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "BriefcaseIcon"
    }
  }), _vm._v(" Tenaga Kerja (PRS):")], 1), _c('b-row', [_c('b-col', [_c('ul', [_c('li', [_c('strong', [_vm._v("Rigger:")]), _vm._v(" " + _vm._s(_vm.jops_pengajuan_dt.tk_rigger) + " ")]), _c('li', [_c('strong', [_vm._v("Supervisor:")]), _vm._v(" " + _vm._s(_vm.jops_pengajuan_dt.tk_supervisor) + " ")])])]), _c('b-col', [_c('ul', [_c('li', [_c('strong', [_vm._v("Tallyman:")]), _vm._v(" " + _vm._s(_vm.jops_pengajuan_dt.tk_tallyman) + " ")]), _c('li', [_c('strong', [_vm._v("Jettyman:")]), _vm._v(" " + _vm._s(_vm.jops_pengajuan_dt.tk_jettyman) + " ")])])]), _c('b-col', [_c('ul', [_c('li', [_c('strong', [_vm._v("HSE:")]), _vm._v(" " + _vm._s(_vm.jops_pengajuan_dt.tk_hse) + " ")]), _c('li', [_c('strong', [_vm._v("Security:")]), _vm._v(" " + _vm._s(_vm.jops_pengajuan_dt.tk_security) + " ")])])])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "text-center yo-card",
    on: {
      "click": function click() {
        return _vm.jasaPelayanan(1);
      }
    }
  }, [_vm.form.jasa_dermaga ? _c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-1",
    staticStyle: {
      "position": "absolute",
      "top": "12px",
      "right": "12px"
    },
    attrs: {
      "title": "Memilih Produk Ini",
      "variant": "success",
      "size": "35"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "30",
      "icon": 'CheckIcon'
    }
  })], 1) : _vm._e(), _c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-1",
    attrs: {
      "title": "Jenis Produk",
      "variant": "light-primary",
      "size": "60"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": 'AnchorIcon'
    }
  })], 1), _c('h3', [_vm._v("Jasa Dermaga")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "text-center yo-card",
    on: {
      "click": function click() {
        return _vm.jasaPelayanan(2);
      }
    }
  }, [_vm.form.jasa_stevodoring ? _c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-1",
    staticStyle: {
      "position": "absolute",
      "top": "12px",
      "right": "12px"
    },
    attrs: {
      "title": "Memilih Produk Ini",
      "variant": "success",
      "size": "35"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "30",
      "icon": 'CheckIcon'
    }
  })], 1) : _vm._e(), _c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-1",
    attrs: {
      "title": "Jenis Produk",
      "variant": "light-primary",
      "size": "60"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": 'TruckIcon'
    }
  })], 1), _c('h3', [_vm._v("Jasa Stevodoring")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "text-center yo-card",
    on: {
      "click": function click() {
        return _vm.jasaPelayanan(3);
      }
    }
  }, [_vm.form.jasa_cargodoring ? _c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-1",
    staticStyle: {
      "position": "absolute",
      "top": "12px",
      "right": "12px"
    },
    attrs: {
      "title": "Memilih Produk Ini",
      "variant": "success",
      "size": "35"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "30",
      "icon": 'CheckIcon'
    }
  })], 1) : _vm._e(), _c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-1",
    attrs: {
      "title": "Jenis Produk",
      "variant": "light-primary",
      "size": "60"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": 'BoxIcon'
    }
  })], 1), _c('h3', [_vm._v("Jasa Cargodoring")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-card', {
    staticClass: "text-center yo-card",
    on: {
      "click": function click() {
        return _vm.jasaPelayanan(4);
      }
    }
  }, [_vm.form.lift_on_off ? _c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-1",
    staticStyle: {
      "position": "absolute",
      "top": "12px",
      "right": "12px"
    },
    attrs: {
      "title": "Memilih Produk Ini",
      "variant": "success",
      "size": "35"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "30",
      "icon": 'CheckIcon'
    }
  })], 1) : _vm._e(), _c('b-avatar', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mb-1",
    attrs: {
      "title": "Jenis Produk",
      "variant": "light-primary",
      "size": "60"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": 'Maximize2Icon'
    }
  })], 1), _c('h3', [_vm._v("Lift ON / OFF")])], 1)], 1)], 1), _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Uraian Kegiatan",
      "label-for": "lbl-Uraian Kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Uraian Kegiatan",
      "vid": "Uraian Kegiatan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrelloIcon"
          }
        })], 1), _c('b-form-input', {
          attrs: {
            "id": "lbl-Uraian Kegiatan",
            "state": errors.length > 0 ? false : null,
            "placeholder": ''
          },
          model: {
            value: _vm.form.uraian_kegiatan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "uraian_kegiatan", $$v);
            },
            expression: "form.uraian_kegiatan"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Note",
      "label-for": "lbl-Note"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Note",
      "vid": "Note"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "AlertCircleIcon"
          }
        })], 1), _c('b-form-input', {
          attrs: {
            "id": "lbl-Note",
            "state": errors.length > 0 ? false : null,
            "placeholder": ''
          },
          model: {
            value: _vm.form.note,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "note", $$v);
            },
            expression: "form.note"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "pill": "",
      "variant": 'light-primary'
    }
  }, [_vm._v(" Jumlah dan Satuan ( Choose one or Choose Both ) ")])], 1)])]), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah",
      "label-for": "lbl-Jumlah1"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jumlah1",
      "vid": "Jumlah1",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          ref: "Jumlah1Input",
          attrs: {
            "id": "lbl-Jumlah1",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": 'Cth 12'
          },
          model: {
            value: _vm.form.jumlah1,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jumlah1", $$v);
            },
            expression: "form.jumlah1"
          }
        }), _c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.Jumlah1Input.focus();
            }
          }
        }, [_vm._v(" TON ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah",
      "label-for": "lbl-Jumlah2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jumlah2",
      "vid": "Jumlah2",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          ref: "Jumlah2Input",
          attrs: {
            "id": "lbl-Jumlah2",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": 'Cth 37.6'
          },
          model: {
            value: _vm.form.jumlah2,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jumlah2", $$v);
            },
            expression: "form.jumlah2"
          }
        }), _c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.Jumlah2Input.focus();
            }
          }
        }, [_vm._v(" M / M3 ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Kegiatan",
      "label-for": "lbl-Waktu Mulai Kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Kegiatan",
      "vid": "Waktu Mulai Kegiatan",
      "rules": {
        required_if: 'Waktu Selesai Kegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Waktu Mulai Kegiatan",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.mulai,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "mulai", $$v);
            },
            expression: "form.mulai"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Kegiatan",
      "label-for": "lbl-Waktu Selesai Kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Kegiatan",
      "vid": "Waktu Selesai Kegiatan",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Waktu Selesai Kegiatan",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.selesai,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "selesai", $$v);
            },
            expression: "form.selesai"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "block": "",
      "variant": _vm.dataID ? 'gradient-warning' : 'gradient-primary',
      "disabled": _vm.fetchLoading
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmit();
      }
    }
  }, [!_vm.fetchLoading ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "DatabaseIcon"
    }
  }) : _vm._e(), _vm.fetchLoading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s(_vm.dataID ? 'Submit Edit Kegiatan Operasional' : 'Submit Tambah Kegiatan Operasional')
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }